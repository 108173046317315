exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-menna-homes-js": () => import("./../../../src/pages/about-menna-homes.js" /* webpackChunkName: "component---src-pages-about-menna-homes-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-design-process-js": () => import("./../../../src/pages/design-process.js" /* webpackChunkName: "component---src-pages-design-process-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-and-news-js": () => import("./../../../src/pages/media-and-news.js" /* webpackChunkName: "component---src-pages-media-and-news-js" */),
  "component---src-pages-our-guarantee-js": () => import("./../../../src/pages/our-guarantee.js" /* webpackChunkName: "component---src-pages-our-guarantee-js" */),
  "component---src-pages-our-homes-and-design-process-js": () => import("./../../../src/pages/our-homes-and-design-process.js" /* webpackChunkName: "component---src-pages-our-homes-and-design-process-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-quality-and-standard-js": () => import("./../../../src/pages/quality-and-standard.js" /* webpackChunkName: "component---src-pages-quality-and-standard-js" */),
  "component---src-pages-testimonial-js": () => import("./../../../src/pages/testimonial.js" /* webpackChunkName: "component---src-pages-testimonial-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

